import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×8 to an 8RM`}</p>
    <p>{`Hamstring Curls 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`20-Step Ups, alternating (53/35 KB) `}<em parentName="p">{`10 per leg`}</em></p>
    <p>{`40-Situps`}</p>
    <p>{`20-Reverse Lunges, alternating (53/35 KB)`}<em parentName="p">{`10 per leg`}</em></p>
    <p>{`1:00 Plank`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will reopen on June 1st at 6:00am!  Check out the
Schedule page for an updated list of class times.  We hope to get back
to our normal class schedule asap and will keep you updated on any
changes.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      